export const OFFICE = 'Office';
export const PARKING = 'Parking';
export const APARTMENT = 'Apartment';
export const PANTRY = 'Pantry';
export const LAND = 'Land';

export const VER_RU = 'ru';
export const VER_UA = 'ua';
export const VER_MD = 'md';


export const APARTMENT_SUBTYPE = 'Apartments_subtype';
export const LOFT = 'Loft';
export const HOUSE = 'House';
export const FLAT = 'Flat';

export const COMPANY_ID = 1;
export const LABEL_WIDTH_SM = 120;
export const ZERO = 0;

export const MODERATOR = 'Moderator';
export const MANAGER = 'Manager';
export const ENTER_KEY = 13;
export const BLOB_TYPE = 'blob';
export const TIME_INTERVAL_REQUEST_CHAT = 10000;
export const VISIBLE = 'visible';
export const HIDDEN = 'hidden';

export const SHORT_TERM = 'Посуточно';
export const LONG_TERM = 'Долгосрочно';


export const ERROR_500_MESSAGE = 'Запрос не может быть обработан. Попробуйте позже.';
export const ERROR_NETWORK = 'Отсутствует подключение к интернету.';

export const NOT_FOUND = 'К сожалению по таким параметрам ничего не найдено.';

export const INVOICE_SUCCESS_REMOVE = 'Инвойс успешно удален';
export const INVOICE_SUCCESS_CREATE = 'Инвойс добавлен успешно';

export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export const INFO = 'info';

export const FORBIDDEN = 'FORBIDDEN';

//bookings statuses

export const BOOKING_ACTIVE = 1;
export const BOOKING_CLOSE = 2;
export const BOOKING_DEBTOR = 4;
export const BOOKING_CREDITOR = 5;

//realty statuses
export const FREE = 1;
export const BOOKED = 2;
export const ENGAGED = 3;
export const CLEANING = 4;
export const PARTIAL_RENT = 5;
export const SOLD = 6;


export const REALTY_TYPES = [
  {value: APARTMENT, name: 'Жильё'},
  {value: PARKING, name: 'Паркинги'},
  {value: OFFICE, name: 'Офисы'},
  {value: PANTRY, name: 'Кладовка'},
];


export const MEETING_STATUSES = [
  {name: 'Назначена', value: 1},
  {name: 'Состоялась', value: 2},
  {name: 'Отменена', value: 3}
];

export const MEETINGS_TYPES = [
  {name: 'Показ', value: 'show'},
  {name: 'Подписание документов', value: 'document_sign'},
  {name: 'Оплаты', value: 'payment'},
  {name: 'Закрытие договора', value: 'contract_closure'},
  {name: 'Передача ключей', value: 'key_handover'},
  {name: 'Тех. проблемы', value: 'tech_problems'}
];


export const REQUEST_RENT = 'rent';
export const REQUEST_INFO = 'info';
export const REQUEST_ON_SELL = 'on_sell';
export const REQUEST_TIME_TO_AVAILABILITY = 'time_to_availability';


export const REALTY_DOCUMENTS = [
  {type: 'type_1', title: 'Выписка ЕГРН'},
  {type: 'type_2', title: 'Свидетельство о государственной регистрации права'},
  {type: 'type_3', title: 'Договор купли-продажи'},
  {type: 'type_4', title: 'Договор долевого участия'},
  {type: 'type_5', title: 'Предварительный договор купли-продажи'},
  {type: 'type_6', title: 'Акт приема передачи'},
  {type: 'type_7', title: 'Соглашение о передаче помещения для производства ремонтных работ'},
  {type: 'type_8', title: 'Справка о состоянии взаимных расчетов'},
  {type: 'type_9', title: 'Уведомление о снятии обременений'},
  {type: 'type_10', title: 'Кадастровый паспорт'},
  {type: 'type_11', title: 'Соглашение о разделе общего имущества'},
  {type: 'type_12', title: 'Договор на эксплуатационное обслуживание'},
];


export const getTypeDocument = (type) => {
  return REALTY_DOCUMENTS_TYPES[type] || 'Другой документ';
};

export const REALTY_DOCUMENTS_TYPES = {
  1: 'Выписка ЕГРН',
  2: 'Свидетельство о государственной регистрации права',
  3: 'Договор купли-продажи',
  4: 'Договор долевого участия',
  5: 'Предварительный договор купли-продажи',
  6: 'Акт приема передачи',
  7: 'Соглашение о передаче помещения для производства ремонтных работ',
  8: 'Справка о состоянии взаимных расчетов',
  9: 'Уведомление о снятии обременений',
  10: 'Кадастровый паспорт',
  11: 'Соглашение о разделе общего имущества',
  12: 'Договор на эксплуатационное обслуживание'
};


export const CLIENT_DONT_PAYMENT_COMMUNAL = 'Клиент не оплатил коммуналку в этом месяце';
export const CLIENT_DONT_SEND_METER_DATA = 'Клиент не подал показания счетчиков в этом месяце';
export const BALANCE_UPDATE = 'Баланс обновлен';
export const PAYMENTS_SUCCESS = 'Платежи получены';

export const TOOLTIP_TOP_START = 'top-start';
export const TOOLTIP_RIGHT_START = 'right-start';
export const TOOLTIP_TOP = 'top';


//client types
export const CLIENT_INDIVIDUAL = 'ClientIndividual';
export const CLIENT_ENTITY = 'ClientEntity';

export const NETWORK_ERROR = 'Network Error';

export const RESPONSE_STATUS_403 = 403;
export const RESPONSE_STATUS_404 = 404;
export const RESPONSE_STATUS_500 = 500;
export const RESPONSE_STATUS_401 = 401;
export const RESPONSE_STATUS_419 = 419;

export const MAX_SITE_META_DESCRIPTION = 158;
export const MAX_SITE_DESCRIPTION = 2000;

export const DATE_FORMAT = {
  DD_MM_YYYY: 'DD.MM.YYYY',
  YYYY_MM_DD: 'YYYY-MM-DD',
  DD_MM_YYYY_HH_mm: 'DD.MM.YYYY, HH:mm',
  HH: 'HH',
  H: 'H',
  HH_mm: 'HH:mm',
  YYYY_MM_DD_HH_MM_SS: 'YYYY-MM-DD HH:mm:ss',
  DD_MMM_YYYY_HH_mm: 'DD MMM YYYY HH:mm',
  YYYY_MM_DD_HH_MM: 'YYYY-MM-DD HH:mm',
  DD_MMM_dd: 'DD MMM dd',
  DD_MMM: 'DD MMM',
  D: 'D',
  YYYY_MM: 'YYYY-MM'
};
