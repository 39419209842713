import { uploadBookingDocument } from '../actions/bookingsActions';
import { BackInTimeIcon, HryvnaIcon, RubIcon } from '../icons';
import React from 'react';
import {
  BOOKING_ACTIVE,
  BOOKING_CLOSE,
  BOOKING_DEBTOR,
  CLIENT_DONT_PAYMENT_COMMUNAL,
  CLIENT_DONT_SEND_METER_DATA,
  TOOLTIP_TOP_START, VER_MD,
  VER_RU,
  VER_UA
} from './Constants';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { Tooltip } from '@material-ui/core';
import { flexAlignCenter, realtyOccupancyColor } from './JSStylesConstants';
import moment from 'moment';

export const BLOB_FILE = 'blob';

export function translateErrors(errors) {

  for ( let i = 0; i < errors.length; i++ ) {
    switch ( errors[i].type ) {
      case 'unsupportedFileType':
        return 'Некорректный тип файла';
      case 'maxSizeExceeded':
        return 'Превышен максимальный размер файла';
      case 'multipleMaxCountExceeded':
        return 'Превышен максимальное количество файлов';
      case 'multipleMaxSizeExceeded':
        return 'Превышен максимальный размер файлов';
      default:
        return 'Ошибка';
    }
  }
}

export function transformBasicUtilities(tariffs) {

  const transformUtilitiesTariffs = [];

  for ( let u in tariffs.basic_utilities ) {
    let tariff = Object.assign({ type: u }, ...tariffs.basic_utilities[u]);
    let logs;

    if ( tariffs.tariff_logs ) {

      for ( let t in tariffs.tariff_logs ) {

        if ( u === t ) {
          logs = Object.assign({ logs: [...tariffs.tariff_logs[t]] }, tariff);
        }

      }
    }

    transformUtilitiesTariffs.push(Object.assign(tariff, logs));
  }

  return transformUtilitiesTariffs;
}


export function transformPaymentOrders(orders) {
  const transformOrders = [];

  for ( let i = 0; i < orders.length; i++ ) {
    transformOrders.push({
      account_id: orders[i].id,
      // realty: orders[i].realty,
      amount: orders[i].debt,
      // management_company: orders[i].management_company
    });
  }

  return transformOrders;
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 ); // eslint-disable-line
    return v.toString(16);
  });
}


export function generatePassword(length) {
  let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    retVal = '';
  for ( let i = 0, n = charset.length; i < length; ++i ) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export function reformatPhoneNumber(phoneNumberString) {
  // let unFormatPhone = ;
  //
  // if(process.env.REACT_APP_LANGUAGE === VER_UA) {
  //   //remove country code 38
  //   return unFormatPhone.slice(2);
  // }
  //
  // if(process.env.REACT_APP_LANGUAGE === VER_RU) {
  //   //remove country code 7
  //   return unFormatPhone.slice(1);
  // }

  return String(phoneNumberString).replace(/\D/g, '');
}


export function transformErrors(errors) {
  let transformErrors = {};

  for ( let e in errors ) {
    let key = errors[e].join(' ').split(' ')[3].replace(/[()]/g, '');
    Object.assign(transformErrors, { [key]: errors[e].join(' ') });
  }

  return transformErrors;
}


export function getColorNotification(type) {
  switch ( type ) {
    case 1:
    case 2:
    case 4:
    case 5:
    case 6:
    case 7:
      return { color: '#FF9D3A' };
    case 3 :
      return { color: '#65AF00' };
    default:
      return { color: '#272F4C' };
  }
}

export function getFormatTypeDownloadDocument(type) {
  switch ( type ) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/msword' :
      return 'doc';
    case 'application/pdf':
      return 'pdf';
    case 'image/jpeg':
      return 'jpeg';
    case 'image/png':
      return 'png';
    case 'image/bmp':
      return 'bmp';
    default:
      return 'docx';
  }
}


export const errorHandler = (errors) => {
  for ( let error in errors ) {
    return errors[error];
  }
};

export const formattedContractFields = (fields = {}) => {
  const formattedField = [];

  for ( let f in fields ) {
    const generateField = {
      name: f,
      value: fields[f],
      ...contractFields[f]
    };
    formattedField.push(generateField);
  }

  return formattedField;
};


export const validationLength = (validationFields) => {
  const errors = {};

  for ( let f in validationFields ) {
    if ( validationFields[f].length === 0 ) {
      if ( f !== 'deposit' || f !== 'depositInWords' ) {
        const error = {
          [f]: 'Поле должно содержать больше 1 символа'
        };
        Object.assign(errors, error);
      }
    }
  }

  return errors;
};


const contractFields = {
  renterCompanyName: {
    placeholder: 'Введите название организации',
    label: 'Название организации',
    individual: true,
  },
  renterDirectorStatus: {
    placeholder: 'Введите должность руководителя',
    label: 'Должность руководителя',
    individual: true,
  },
  renterDirectorName: {
    placeholder: 'Введите ФИО руководителя',
    label: 'ФИО руководителя',
    individual: true,
  },
  actFrom: {
    placeholder: 'Введите номер документа',
    label: 'Действует на основании',
    individual: true,
  },
  renterINN: {
    placeholder: 'Введите ИНН',
    label: 'ИНН',
    individual: true,
  },
  renterKPP: {
    placeholder: 'Введите КПП',
    label: 'КПП',
    individual: true,
  },
  renterOGRN: {
    placeholder: 'Введите ОГРН',
    label: 'ОГРН',
    individual: true,
  },
  renterCompanyRegistrationAddress: {
    placeholder: 'Введите адрес регистрации',
    label: 'Адрес регистрации',
    individual: true,
  },
  renterCompanyPostAddress: {
    placeholder: 'Введите почтовый адрес',
    label: 'Почтовый адрес',
    individual: true,
  },
  renterBankAccountNumber: {
    placeholder: 'Введите расчетный счет',
    label: 'Расчетный счет',
    individual: true,
  },
  renterBankName: {
    placeholder: 'Введите наименование банка',
    label: 'Наименование банка',
    individual: true,
  },
  renterBankBikNumber: {
    placeholder: 'Введите БИК банка',
    label: 'БИК банка',
    individual: true,
  },
  renterBankKorrespAccount: {
    placeholder: 'Введите корр счет',
    label: 'Корр счет',
    individual: true,
  },
  costInWords: {
    placeholder: 'Введите стоимость аренды прописью',
    label: 'Стоимость аренды прописью',
    contract: true,
  },
  agreementNumber: {
    placeholder: 'Введите номер договора',
    label: 'Договор',
    contract: true,
  },
  floor: {
    placeholder: 'Введите номер этажа',
    label: 'Этаж',
    disabled: true,
    number: true,
    realty: true,
  },
  square: {
    placeholder: 'Введите площадь',
    label: 'Площадь',
    number: true,
    disabled: true,
    realty: true,
  },
  certEGRNPnubmer: {
    placeholder: 'Введите кадастровый номер',
    label: 'Кадастровый номер',
    number: true,
    realty: true,
  },
  depositInWords: {
    placeholder: 'Введите сумму депозита прописью',
    label: 'Сумма депозита прописью',
    contract: true,
  },
  renterFirstName: {
    placeholder: 'Введите имя',
    label: 'Имя',
    individual: true,
  },
  renterLastName: {
    placeholder: 'Введите фамилию',
    label: 'Фамилия',
    individual: true,
  },
  renterMiddleName: {
    placeholder: 'Введите отчество',
    label: 'Отчество',
    individual: true,
  },
  renterBirthDay: {
    placeholder: 'Введите дату рождения',
    label: 'Дата рождения',
    dateFormat: true,
    individual: true,
  },
  renterBirthPlace: {
    placeholder: 'Точь в точь как в паспорте',
    label: 'Место рождения',
    individual: true,
  },
  renterPassportSeries: {
    placeholder: 'Введите серию паспорта',
    label: 'Серия паспорта',
    individual: true,
  },
  renterPassportNumber: {
    placeholder: 'Введите номер паспорта',
    label: 'Номер паспорта',
    individual: true,
  },
  renterPassportWasGivenBy: {
    placeholder: 'Введите кем выдан паспорт',
    label: 'Кем выдан паспорт',
    individual: true,
  },
  renterPassportDevisionCode: {
    placeholder: 'Введите код подразделения',
    label: 'Код подразделения',
    individual: true,
  },
  renterPassportDateOfGiving: {
    placeholder: 'Введите когда выдан паспорт',
    label: 'Когда выдан паспорт',
    dateFormat: true,
    individual: true,
  },
  renterRegistrationAddress: {
    placeholder: 'Введите адрес регистрации',
    label: 'Адрес регистрации',
    individual: true,
  },
  renterPostAddress: {
    placeholder: 'Введите почтовый адрес',
    label: 'Почтовый адрес',
    individual: true,
  },
  renterPassportValidity: {
    placeholder: 'Паспорт действует до',
    label: 'Паспорт действует до',
    dateFormat: true,
    individual: true,
  },
  renterCountryCitizenship: {
    placeholder: 'Страна',
    label: 'Выберите страну',
    locationFormat: true,
    individual: true,
  },
  phone: {
    placeholder: 'Введите телефон',
    label: 'Телефон',
    phoneFormat: true,
    individual: true,
  },
  email: {
    placeholder: 'Введите email',
    label: 'Email',
    individual: true,
  },
  cost: {
    placeholder: 'Введите стоимость аренды',
    label: 'Стоимость аренды',
    contract: true,
  },
  arrivalDate: {
    placeholder: 'Дата въезда',
    label: 'Дата въезда',
    dateFormat: true,
    contract: true,
  },
  departureDate: {
    placeholder: 'Дата выезда',
    label: 'Дата выезда',
    dateFormat: true,
    contract: true,
  },
  deposit: {
    placeholder: 'Введите сумму депозита',
    label: 'Ожидаемый депозит',
    number: true,
    // disabled: true,
    contract: true,
  },
  date: {
    placeholder: 'Введите дату',
    label: 'Дата договора',
    dateFormat: true,
    contract: true,
  },
  city: {
    label: 'Город',
    cityFormat: true,
    disabled: true,
    realty: true,
  },
  address: {
    placeholder: 'Введите адрес',
    label: 'Адрес',
    disabled: true,
    realty: true,
  }
};

export const uploadGoogleCloudDoc = (id, response, type, format) => {

  let sendFile = new File([response], `Document.${ format }`, { type: type });

  const data = new FormData();

  data.append('type', 4);
  data.append('file', sendFile);

  uploadBookingDocument(id, data)
    .then(() => {
    })
    .catch(() => {
    });

};

export const setPageTitle = (title) => {
  document.title = title;
};

export const capitalizeFirstLetter = (str) => {
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
};

export const getTransformRealtyExternalResources = (allRealty) => {
  let newRealtyWithResources = [];
  let currentObject;
  let resourcesCount = 4;

  for ( let i = 0; i < allRealty.length; i++ ) {

    currentObject = {
      realty_name: allRealty[i].realty_name,
      realty_id: allRealty[i].realty_id,
    };

    if ( !!Object.keys(allRealty[i].external_resources).length ) {
      resourcesCount = Object.keys(allRealty[i].external_resources).length;

      let finishResources = [];

      let allRealtyResources = Object.values(allRealty[i].external_resources);

      allRealtyResources.forEach(item => {
        finishResources.push(...item);
      });

      let sortedResources = finishResources.sort((a, b) => {
        if ( a.resource > b.resource ) return 1;
        if ( a.resource < b.resource ) return -1;
        return 0;
      });

      currentObject.external_resources = sortedResources;
    } else {
      currentObject.external_resources = Array.from({ length: resourcesCount }).fill({});
    }

    newRealtyWithResources.push(currentObject);
  }

  return newRealtyWithResources;
};

export const getFormatNumber = (value) => {
  if ( !value ) return null;

  let formatter = new Intl.NumberFormat('ru-RU', {});

  return formatter.format(+value);
};

export const sortable = (data) => {
  return data.sort((a, b) => {
    if ( a.resource > b.resource ) {
      return 1;
    }

    if ( a.resource < b.resource ) {
      return -1;
    }

    return 0;
  });
};


//todo: dont use
export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = String(phoneNumberString).replace(/\D/g, '');

  // if ( process.env.REACT_APP_LANGUAGE === VER_RU ) {
  let matchRu = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if ( matchRu ) {

    let intlCode = ( matchRu[1] ? '+7 ' : '' );
    return [intlCode, '(', matchRu[2], ') ', matchRu[3], '-', matchRu[4], '-', matchRu[5]].join('');
  }
  // }

  // if ( process.env.REACT_APP_LANGUAGE === VER_UA ) {
  let matchUa = cleaned.match(/^(38|)?(\d{3})(\d{2})(\d{2})(\d{3})$/);
  if ( matchUa ) {
    let intlCode = ( matchUa[1] ? '+38 ' : '' );
    return [intlCode, '(', matchUa[2], ') ', matchUa[3], '-', matchUa[4], '-', matchUa[5]].join('');
  }
  // }


  return null;
};

export const transformRealtyFeedsChanges = (changes) => {
  let rows = [];

  for ( let item in changes ) {

    if ( typeof changes[item] === 'object' ) {
      rows.push({
        name: item,
        value: JSON.stringify(changes[item])
      });
    } else {
      rows.push({
        name: item,
        value: changes[item]
      });
    }

  }

  return rows;
};


export const getBookingUtilitiesStatus = (booking) => {
  if ( booking.with_utility_bills && !booking.isProvidedMetersData && booking.status === BOOKING_ACTIVE ) {
    return (
      <Tooltip title={ CLIENT_DONT_SEND_METER_DATA }
               arrow
               placement={ TOOLTIP_TOP_START }>
        <div className="money-icon" style={ { ...flexAlignCenter } }>
          <BackInTimeIcon style={ { fontSize: 32, color: 'red' } }/>
        </div>
      </Tooltip>
    );
  }
};

export const getBookingUtilitiesPaymentStatus = (booking) => {
  if ( booking.with_utility_bills && booking.status === BOOKING_ACTIVE && !booking.utilities_paid ) {
    return (
      <Tooltip title={ CLIENT_DONT_PAYMENT_COMMUNAL }
               arrow
               placement={ TOOLTIP_TOP_START }>
        <MonetizationOnOutlinedIcon className="utilities-icon"
                                    style={ { fontSize: 35, marginLeft: 16, color: 'red' } }/>
      </Tooltip>

    );
  }
};


export const getBookingStatus = (booking) => {
  if ( booking.status === BOOKING_ACTIVE ) {
    return <LockOpenOutlinedIcon fontSize={ 'large' } style={ { color: 'green' } }/>;
  }

  if ( booking.status === BOOKING_CLOSE ) {
    return <LockOutlinedIcon fontSize={ 'large' } style={ { color: 'silver' } }/>;
  }

  if ( booking.status === BOOKING_DEBTOR ) {
    return <AttachMoneyOutlinedIcon fontSize={ 'large' } style={ { color: 'green' } }/>;
  }
};


export const getCurrentAppVersion = () => {
  return process.env.REACT_APP_LANGUAGE;
};


export const getCurrentCurrency = () => {
  switch ( process.env.REACT_APP_LANGUAGE ) {
    case ( VER_UA ):
      return 'грн';
    case ( VER_RU ):
      return 'руб.';
    default:
      return 'руб.';
  }
};

export const getCurrentPriceDay = () => {
  switch ( process.env.REACT_APP_LANGUAGE ) {
    case ( VER_UA ):
      return 'грн/доба';
    case ( VER_RU ):
      return 'руб./сутки';
    default:
      return 'руб./сутки';
  }
};

export const getCurrentRealtyProlongTariff = () => {
  switch ( process.env.REACT_APP_LANGUAGE ) {
    case ( VER_UA ):
      return 'місяць/доба';
    case ( VER_RU ):
      return 'мес./сутки';
    default:
      return 'мес./сутки';
  }
};

export const getCurrentCurrencyIcon = () => {
  switch ( process.env.REACT_APP_LANGUAGE ) {
    case ( VER_UA ):
      return <HryvnaIcon/>;
    case ( VER_RU ):
      return <RubIcon/>;
    default:
      return <RubIcon/>;
  }
};


export const getLocalePhoneMask = () => {
  switch ( process.env.REACT_APP_LANGUAGE ) {
    case ( VER_UA ):
      return {
        placeholder: '+380 (__) ___-__-__',
        mask: '+380 (99) 999-99-99'
      };
    case ( VER_RU ):
      return {
        placeholder: '+7 (___) ___-__-__',
        mask: '+7 (999) 999-99-99'
      };
    case ( VER_MD ):
      return {
        placeholder: '+373 (__) ___-___',
        mask: '+373 (99) 999-999'
      };
    default:
      return {
        placeholder: '+7 (___) ___-__-__',
        mask: '+7 (999) 999-99-99'
      };
  }
};

export const requestFromToQuery = (period, long) => {
  // let from = moment().subtract(long, period).format(DATE_FORMAT.YYYY_MM_DD);
  // let to = moment().format(DATE_FORMAT.YYYY_MM_DD);
  let from = moment().subtract(period, long).unix();
  let to = moment().unix();

  return { from, to };
};

export const getRealtyStatisticDayColor = (days_payed, days_not_payed) => {
  const notPayedPercent = 0;
  const payedPercent = 65;

  let percent = days_payed / ( days_payed + days_not_payed ) * 100;


  switch ( true ) {
    case ( percent === notPayedPercent ):
      return realtyOccupancyColor.white;
    case ( percent < payedPercent ):
      return realtyOccupancyColor.lightGreen;
    case ( percent > payedPercent ):
      return realtyOccupancyColor.green;
    default:
      return realtyOccupancyColor.white;
  }
};
