import {VER_RU} from './Constants';
import ClientsListPage from '../pages/Client/ClientsListPage';
import ClientEditPage from '../pages/Client/ClientEditPage';
import BookingRequestsListPage from '../pages/BookingRequests/BookingRequestsListPage';
import BookingRequestEditPage from '../pages/BookingRequests/BookingRequestEditPage';
import ManagersListPage from '../pages/Manager/ManagersListPage';
import ManagerEditPage from '../pages/Manager/ManagerEditPage';
import RealtyListPage from '../pages/Realty/RealtyListPage';
import RealtyCreatePage from '../pages/Realty/RealtyCreatePage';
import RealtyEditPage from '../pages/Realty/RealtyEditPage';
import ModeratorListPage from '../pages/Moderator/ModeratorsListPage';
import ModeratorEditPage from '../pages/Moderator/ModeratorEditPage';
import PermissionsListPage from '../pages/Permissions/PermissionsListPage';
import PermissionsEditPage from '../pages/Permissions/PermissionsEditPage';
import BookingsListPage from '../pages/Booking/BookingsListPage';
import BookingCreatePage from '../pages/Booking/BookingCreatePage';
import BookingEditPage from '../pages/Booking/BookingEditPage';
import ManagementCompaniesListPage from '../pages/ManagementCompany/ManagementCompaniesListPage';
import ManagementCompanyEditPage from '../pages/ManagementCompany/ManagementCompanyEditPage';
import UtilitiesInvoicesListPage from '../pages/Utilities/UtilitiesInvoicesListPage';
import UtilitiesMetersDataListPage from '../pages/Utilities/UtilitiesMetersDataListPage';
import PaymentOrdersListPage from '../pages/Utilities/PaymentOrdersListPage';
import PaymentActsListPage from '../pages/Utilities/PaymentActsListPage';
import TochkaPaymentsListPage from '../pages/Tochka/TochkaPaymentsListPage';
import PaydeskPaymentsListPage from '../pages/Paydesk/PaydeskPaymentsListPage';
import ResourcesListPage from '../pages/Resources/ResourcesListPage';
import ClientsSupportPage from '../pages/ClientsSupport/ClientsSupportPage';
import TechnicalSupportPage from '../pages/TechnicalSupport/TechnicalSupportPage';
import Logs from '../pages/Logs/LogsListPage';
import StatisticsListPage from '../pages/Statistics/StatisticsListPage';
import RealtyGroupsListPage from '../pages/RealtyGroups/RealtyGroupsListPage';
import RealtyGroupsEditPage from '../pages/RealtyGroups/RealtyGroupsEditPage';
import NotificationsListPage from '../pages/Notifications/NotificationsListPage';
import MeetingsListPage from '../pages/Meetings/MeetingsListPage';
import MeetingCreatePage from '../pages/Meetings/MeetingCreatePage';
import MeetingEditPage from '../pages/Meetings/MeetingsEditPage';
import RealtyFeedsListPage from '../pages/Realty/RealtyFeedsListPage';
import { RealtyOccupancyListPage } from '../pages/Realty';

export const MAIN_ROUTE = '/';
export const NOT_FOUND_ROUTE = '/404';
export const LOGIN_ROUTE = '/login';
export const CLIENTS_ROUTE = '/clients';
export const CLIENT_ROUTE = `${ CLIENTS_ROUTE }/:id`;
export const BOOKING_REQUESTS_ROUTE = '/booking/requests';
export const BOOKING_REQUEST_ROUTE = `${ BOOKING_REQUESTS_ROUTE }/:id`;
export const MANAGERS_ROUTE = '/managers';
export const MANAGER_ROUTE = `${ MANAGERS_ROUTE }/:id`;
export const REALTY_LIST_ROUTE = '/realty';
export const NEW_REALTY_ROUTE = `${ REALTY_LIST_ROUTE }/new`;
export const REALTY_ROUTE = `${ REALTY_LIST_ROUTE }/:id`;
export const MODERATORS_ROUTE = '/moderators';
export const MODERATOR_ROUTE = `${ MODERATORS_ROUTE }/:id`;
export const PERMISSIONS_ROUTE = '/permissions';
export const PERMISSION_ROUTE = `${ PERMISSIONS_ROUTE }/:id`;
export const BOOKINGS_ROUTE = '/bookings';
export const NEW_BOOKING_ROUTE = `${ BOOKINGS_ROUTE }/new`;
export const BOOKING_ROUTE = `${ BOOKINGS_ROUTE }/:id`;
export const MANAGEMENT_COMPANIES_ROUTE = '/management-companies';
export const MANAGEMENT_COMPANY_ROUTE = `${ MANAGEMENT_COMPANIES_ROUTE }/:id`;
export const COMMUNAL_ROUTE = '/communal';
export const INVOICES_ROUTE = `${ COMMUNAL_ROUTE }/invoices`;
export const METERS_DATA_ROUTE = `${ COMMUNAL_ROUTE }/meters-data`;
export const PAYMENT_ACTS_ROUTE = `${ COMMUNAL_ROUTE }/payment-acts`;
export const PAYMENT_ORDERS_ROUTE = `${ COMMUNAL_ROUTE }/payment-orders`;
export const PAYMENTS_ROUTE = '/payments';
export const PAYMENTS_BANK_ROUTE = `${ PAYMENTS_ROUTE }/tochka`;
export const RESOURCES_ROUTE = '/resources';
export const SUPPORT_ROUTE = '/support';
export const TECHNICAL_SUPPORT_ROUTE = '/technical-support';
export const LOGS_ROUTE = '/logs';
export const STATISTIC_ROUTE = '/statistic';
export const REALTY_GROUPS_ROUTE = '/realty-groups';
export const REALTY_GROUP_ROUTE = `${ REALTY_GROUPS_ROUTE }/:id`;
export const NOTIFICATIONS_ROUTE = '/notifications';
export const MEETINGS_ROUTE = '/meetings';
export const NEW_MEETING_ROUTE = `${ MEETINGS_ROUTE }/new`;
export const MEETING_ROUTE = `${ MEETINGS_ROUTE }/:id`;
export const REALTY_FEED = `${ REALTY_LIST_ROUTE }/:id/feed`;
export const REALTY_OCCUPANCY= `/realty-occupancy`;


export const ROUTING =  {
  ru: [
    {
      url: CLIENTS_ROUTE,
      component: ClientsListPage
    },
    {
      url: CLIENT_ROUTE,
      component: ClientEditPage
    },
    {
      url: BOOKING_REQUESTS_ROUTE,
      component: BookingRequestsListPage
    },
    {
      url: BOOKING_REQUEST_ROUTE,
      component: BookingRequestEditPage
    },
    {
      url: MANAGERS_ROUTE,
      component: ManagersListPage
    },
    {
      url: MANAGER_ROUTE,
      component: ManagerEditPage
    },
    {
      url: REALTY_LIST_ROUTE,
      component: RealtyListPage
    },
    {
      url: NEW_REALTY_ROUTE,
      component: RealtyCreatePage
    },
    {
      url: REALTY_ROUTE,
      component: RealtyEditPage
    },
    {
      url: MODERATORS_ROUTE,
      component: ModeratorListPage
    },
    {
      url: MODERATOR_ROUTE,
      component: ModeratorEditPage
    },
    {
      url: PERMISSIONS_ROUTE,
      component: PermissionsListPage
    },
    {
      url: PERMISSION_ROUTE,
      component: PermissionsEditPage
    },
    {
      url: BOOKINGS_ROUTE,
      component: BookingsListPage
    },
    {
      url: NEW_BOOKING_ROUTE,
      component: BookingCreatePage
    },
    {
      url: BOOKING_ROUTE,
      component: BookingEditPage
    },
    {
      url: MANAGEMENT_COMPANIES_ROUTE,
      component: ManagementCompaniesListPage
    },
    {
      url: MANAGEMENT_COMPANY_ROUTE,
      component: ManagementCompanyEditPage
    },
    {
      url: INVOICES_ROUTE,
      component: UtilitiesInvoicesListPage
    },
    {
      url: METERS_DATA_ROUTE,
      component: UtilitiesMetersDataListPage
    },
    {
      url: PAYMENT_ORDERS_ROUTE,
      locale: VER_RU,
      component: PaymentOrdersListPage
    },
    {
      url: PAYMENT_ACTS_ROUTE,
      locale: VER_RU,
      component: PaymentActsListPage
    },
    {
      url: PAYMENTS_BANK_ROUTE,
      locale: VER_RU,
      component: TochkaPaymentsListPage
    },
    {
      url: PAYMENTS_ROUTE,
      locale: VER_RU,
      component: PaydeskPaymentsListPage
    },
    {
      url: RESOURCES_ROUTE,
      component: ResourcesListPage
    },
    {
      url: SUPPORT_ROUTE,
      component: ClientsSupportPage
    },
    {
      url: TECHNICAL_SUPPORT_ROUTE,
      component: TechnicalSupportPage
    },
    {
      url: LOGS_ROUTE,
      component: Logs
    },
    {
      url: STATISTIC_ROUTE,
      component: StatisticsListPage
    },
    {
      url: MAIN_ROUTE,
      component: StatisticsListPage
    },
    {
      url: REALTY_GROUPS_ROUTE,
      component: RealtyGroupsListPage
    },
    {
      url: REALTY_GROUP_ROUTE,
      component: RealtyGroupsEditPage
    },
    {
      url: NOTIFICATIONS_ROUTE,
      component: NotificationsListPage
    },
    {
      url: MEETINGS_ROUTE,
      component: MeetingsListPage
    },
    {
      url: NEW_MEETING_ROUTE,
      component: MeetingCreatePage
    },
    {
      url: MEETING_ROUTE,
      component: MeetingEditPage
    },
    {
      url: REALTY_FEED,
      component: RealtyFeedsListPage
    },
    {
      url: REALTY_OCCUPANCY,
      component: RealtyOccupancyListPage
    }
  ],
  ua: [
    {
      url: CLIENTS_ROUTE,
      component: ClientsListPage
    },
    {
      url: CLIENT_ROUTE,
      component: ClientEditPage
    },
    {
      url: BOOKING_REQUESTS_ROUTE,
      component: BookingRequestsListPage
    },
    {
      url: BOOKING_REQUEST_ROUTE,
      component: BookingRequestEditPage
    },
    {
      url: MANAGERS_ROUTE,
      component: ManagersListPage
    },
    {
      url: MANAGER_ROUTE,
      component: ManagerEditPage
    },
    {
      url: REALTY_LIST_ROUTE,
      component: RealtyListPage
    },
    {
      url: NEW_REALTY_ROUTE,
      component: RealtyCreatePage
    },
    {
      url: REALTY_ROUTE,
      component: RealtyEditPage
    },
    {
      url: MODERATORS_ROUTE,
      component: ModeratorListPage
    },
    {
      url: MODERATOR_ROUTE,
      component: ModeratorEditPage
    },
    {
      url: PERMISSIONS_ROUTE,
      component: PermissionsListPage
    },
    {
      url: PERMISSION_ROUTE,
      component: PermissionsEditPage
    },
    {
      url: BOOKINGS_ROUTE,
      component: BookingsListPage
    },
    {
      url: NEW_BOOKING_ROUTE,
      component: BookingCreatePage
    },
    {
      url: BOOKING_ROUTE,
      component: BookingEditPage
    },
    {
      url: MANAGEMENT_COMPANIES_ROUTE,
      component: ManagementCompaniesListPage
    },
    {
      url: MANAGEMENT_COMPANY_ROUTE,
      component: ManagementCompanyEditPage
    },
    {
      url: INVOICES_ROUTE,
      component: UtilitiesInvoicesListPage
    },
    {
      url: METERS_DATA_ROUTE,
      component: UtilitiesMetersDataListPage
    },
    {
      url: RESOURCES_ROUTE,
      component: ResourcesListPage
    },
    {
      url: SUPPORT_ROUTE,
      component: ClientsSupportPage
    },
    {
      url: TECHNICAL_SUPPORT_ROUTE,
      component: TechnicalSupportPage
    },
    {
      url: LOGS_ROUTE,
      component: Logs
    },
    {
      url: STATISTIC_ROUTE,
      component: StatisticsListPage
    },
    {
      url: MAIN_ROUTE,
      component: StatisticsListPage
    },
    {
      url: REALTY_GROUPS_ROUTE,
      component: RealtyGroupsListPage
    },
    {
      url: REALTY_GROUP_ROUTE,
      component: RealtyGroupsEditPage
    },
    {
      url: NOTIFICATIONS_ROUTE,
      component: NotificationsListPage
    },
    {
      url: MEETINGS_ROUTE,
      component: MeetingsListPage
    },
    {
      url: NEW_MEETING_ROUTE,
      component: MeetingCreatePage
    },
    {
      url: MEETING_ROUTE,
      component: MeetingEditPage
    },
    {
      url: REALTY_FEED,
      component: RealtyFeedsListPage
    },
    {
      url: PAYMENTS_ROUTE,
      component: PaydeskPaymentsListPage
    },
    {
      url: REALTY_OCCUPANCY,
      component: RealtyOccupancyListPage
    }
  ],
  md: [
    {
      url: CLIENTS_ROUTE,
      component: ClientsListPage
    },
    {
      url: CLIENT_ROUTE,
      component: ClientEditPage
    },
    {
      url: BOOKING_REQUESTS_ROUTE,
      component: BookingRequestsListPage
    },
    {
      url: BOOKING_REQUEST_ROUTE,
      component: BookingRequestEditPage
    },
    {
      url: MANAGERS_ROUTE,
      component: ManagersListPage
    },
    {
      url: MANAGER_ROUTE,
      component: ManagerEditPage
    },
    {
      url: REALTY_LIST_ROUTE,
      component: RealtyListPage
    },
    {
      url: NEW_REALTY_ROUTE,
      component: RealtyCreatePage
    },
    {
      url: REALTY_ROUTE,
      component: RealtyEditPage
    },
    {
      url: MODERATORS_ROUTE,
      component: ModeratorListPage
    },
    {
      url: MODERATOR_ROUTE,
      component: ModeratorEditPage
    },
    {
      url: PERMISSIONS_ROUTE,
      component: PermissionsListPage
    },
    {
      url: PERMISSION_ROUTE,
      component: PermissionsEditPage
    },
    {
      url: BOOKINGS_ROUTE,
      component: BookingsListPage
    },
    {
      url: NEW_BOOKING_ROUTE,
      component: BookingCreatePage
    },
    {
      url: BOOKING_ROUTE,
      component: BookingEditPage
    },
    {
      url: MANAGEMENT_COMPANIES_ROUTE,
      component: ManagementCompaniesListPage
    },
    {
      url: MANAGEMENT_COMPANY_ROUTE,
      component: ManagementCompanyEditPage
    },
    {
      url: INVOICES_ROUTE,
      component: UtilitiesInvoicesListPage
    },
    {
      url: METERS_DATA_ROUTE,
      component: UtilitiesMetersDataListPage
    },
    {
      url: PAYMENT_ORDERS_ROUTE,
      locale: VER_RU,
      component: PaymentOrdersListPage
    },
    {
      url: PAYMENT_ACTS_ROUTE,
      locale: VER_RU,
      component: PaymentActsListPage
    },
    {
      url: PAYMENTS_BANK_ROUTE,
      locale: VER_RU,
      component: TochkaPaymentsListPage
    },
    {
      url: PAYMENTS_ROUTE,
      locale: VER_RU,
      component: PaydeskPaymentsListPage
    },
    {
      url: RESOURCES_ROUTE,
      component: ResourcesListPage
    },
    {
      url: SUPPORT_ROUTE,
      component: ClientsSupportPage
    },
    {
      url: TECHNICAL_SUPPORT_ROUTE,
      component: TechnicalSupportPage
    },
    {
      url: LOGS_ROUTE,
      component: Logs
    },
    {
      url: STATISTIC_ROUTE,
      component: StatisticsListPage
    },
    {
      url: MAIN_ROUTE,
      component: StatisticsListPage
    },
    {
      url: REALTY_GROUPS_ROUTE,
      component: RealtyGroupsListPage
    },
    {
      url: REALTY_GROUP_ROUTE,
      component: RealtyGroupsEditPage
    },
    {
      url: NOTIFICATIONS_ROUTE,
      component: NotificationsListPage
    },
    {
      url: MEETINGS_ROUTE,
      component: MeetingsListPage
    },
    {
      url: NEW_MEETING_ROUTE,
      component: MeetingCreatePage
    },
    {
      url: MEETING_ROUTE,
      component: MeetingEditPage
    },
    {
      url: REALTY_FEED,
      component: RealtyFeedsListPage
    },
    {
      url: REALTY_OCCUPANCY,
      component: RealtyOccupancyListPage
    }
  ],
}
